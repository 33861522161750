<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
                <b-overlay :show="loadingState">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Organization" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="program_info.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option disabled :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Project Name" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="project_info_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('research_manage.project_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="program_info.project_info_id"
                                            :options="projectList"
                                            id="project_info_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option disabled :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Program Area Name(En)" vid="program_area_name" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="program_area_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.program_area_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="program_area_name"
                                        v-model="program_info.program_area_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Program Area Name(Bn)" vid="program_area_name_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="program_area_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.program_area_name_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="program_area_name_bn"
                                        v-model="program_info.program_area_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Major Objective(En)" vid="major_objective" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="major_objective"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.major_objective')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="major_objective"
                                        v-model="program_info.major_objective"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Major Objective(Bn)" vid="major_objective_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="major_objective_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.major_objective_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="major_objective_bn"
                                        v-model="program_info.major_objective_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Objectives(En)" vid="objectives" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="objectives"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.objectives')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-textarea
                                        id="objectives"
                                        v-model="program_info.objectives"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Objectives(Bn)" vid="objectives_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="objectives_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.objectives_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-textarea
                                        id="objectives_bn"
                                        v-model="program_info.objectives_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Annual Budget(Lac Tk)" vid="annual_budget" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="annual_budget"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.annual_budget')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="annual_budget"
                                        v-model="program_info.annual_budget"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Expected Output(En)" vid="expected_output" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expected_output"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.expected_output_en')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                        type="number"
                                        id="expected_output"
                                        v-model="program_info.expected_output"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Expected Output(Bn)" vid="expected_output_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expected_output_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.expected_output_bn')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-textarea
                                        type="number"
                                        id="expected_output_bn"
                                        v-model="program_info.expected_output_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Program Status(Bn)" vid="program_status" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="program_status"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.program_status')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        id="program_status"
                                        v-model="program_info.program_status"
                                        :options="statusList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                            <template v-slot:first>
                                                <b-form-select-option disabled :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Date of Initiation" vid="data_of_initiation" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="data_of_initiation"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.date_of_initiation')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            type="date"
                                            plain
                                            v-model="program_info.date_of_initiation"
                                            id="date_of_initiation"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="PD Name" vid="pd_name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="pd_name"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.pd_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            plain
                                            v-model="program_info.pd_name"
                                            id="pd_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="PD Name(Bn)" vid="pd_name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="pd_name_bn"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.pd_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            plain
                                            v-model="program_info.pd_name_bn"
                                            id="pd_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            </b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Other Document" vid="other_document">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="other_document"
                                            >
                                            <template v-slot:label>
                                                {{$t('research_manage.other_document')}}
                                            </template>
                                            <b-form-file
                                                plain
                                                v-on:change="onFileChange"
                                                accept=".jpg, .doc, .docx, .zip, .pdf"
                                                v-model="other_document"
                                                id="other_document"
                                            >
                                            </b-form-file>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Division" vid="division_name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="division_name"
                                            slot-scope="{ valid,errors }"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                            </template>
                                                <v-select
                                                    multiple
                                                    v-model="program_info.division_name"
                                                    :options="divisionList"
                                                    :reduce="div => div.value"
                                                    value="value"
                                                    label="text"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                </v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { programInfoStore, programInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        // statusList: [
        //   { value: '1', text: 'New' },
        //   { value: '2', text: 'Continuous' }
        // ],
        form: new FormData(),
        program_info: {
            org_id: '',
            program_area_name: '',
            program_area_name_bn: '',
            project_description_bn: '',
            major_objective: '',
            major_objective_bn: '',
            objectives: '',
            objectives_bn: '',
            annual_budget: '',
            expected_output: '',
            expected_output_bn: '',
            date_of_initiation: '',
            program_status: '',
            pd_name: '',
            pd_name_bn: '',
            division_name: [],
            other_document: '',
            project_info_id: ''
        },
        other_document: null
    }
  },
  computed: {
    orgList: function () {
      const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      return objectData.map(org => {
            return { value: org.value, text: this.$i18n.locale === 'en' ? org.text_en : org.text_bn }
          })
    },
    divisionList: function () {
        if (this.$store.state) {
            return this.$store.state.AgriResearch.commonObj.researchDivisionList.map(div => {
            return { value: div.value, text: this.$i18n.locale === 'en' ? div.text_en : div.text_bn }
          })
        }
        return 0
    },
    projectList: function () {
        if (this.$store.state) {
            return this.$store.state.AgriResearch.commonObj.projectList.map(project => {
            return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
          })
        }
        return 0
    },
    statusList: function () {
      return this.$store.state.commonObj.programStatusList
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getProjectData()
        // Object.freeze(tmp)
        this.program_info = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    onFileChange (e) {
      this.other_document = e.target.files[0]
    },
    getProjectData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      this.$set(tmpData, 'division_name', tmpData.division_name.split(','))
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        var formData = new FormData()
        Object.keys(this.program_info).map(key => {
            if (key === 'other_document') {
                formData.append(key, this.other_document)
            } else {
                formData.append(key, this.program_info[key])
            }
        })
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        if (this.id) {
            result = await RestApi.putData(agriResearchServiceBaseUrl, `${programInfoUpdate}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(agriResearchServiceBaseUrl, programInfoStore, formData)
        }
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
            this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? 'Data updated successfully' : 'Data save successfully',
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
            })
        }
    }
  }
}
</script>
