<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col md="12" class="table-responsive">
                  <table class="table">
                    <tr>
                      <th>{{ $t('org_pro.organization') }}</th>
                      <td>{{ orgList.find(e=>e.value===item.org_id).text }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.program_area_name') }}</th>
                      <td>{{ item.program_area_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.annual_budget') }}</th>
                      <td>{{ item.annual_budget }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.major_objective') }}</th>
                      <td v-if="localeLang === 'bn'">{{ item.major_objective_bn }}</td>
                      <td v-else>{{ item.major_objective }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.objectives') }}</th>
                      <td v-if="localeLang === 'bn'">{{ item.objectives_bn }}</td>
                      <td v-else>{{ item.objectives }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.expected_output') }}</th>
                      <td>{{ item.expected_output }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.program_status') }}</th>
                      <td >{{ $store.state.commonObj.programStatusList.find(e => e.value == item.program_status).text }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.date_of_initiation') }}</th>
                      <td >{{ item.date_of_initiation }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.pd_name') }}</th>
                      <td v-if="localeLang === 'bn'">{{ item.pd_name_bn }}</td>
                      <td v-else>{{ item.pd_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('globalTrans.division') }}</th>
                      <td>{{ item.division_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('research_manage.other_document') }}</th>
                      <td v-if="item.other_document"><a :href="item.other_document" target="_blank">Get Document</a></td>
                      <td v-else>N/A</td>
                    </tr>
                  </table>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['item'],
  components: {
  },
  created () {
      const tmp = this.item
      this.program = tmp
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        program: {},
        options: [
            { value: 1, text: 'Coordinate Research' },
            { value: 2, text: 'Plain Research' }
        ]
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    orgList: function () {
        const objectData = this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
        return objectData.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
    }
  },
  methods: {
  }
}
</script>
