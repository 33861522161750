
export default {
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        loadingState () {
            if (this.loading) {
                return true
            } else {
                return false
            }
        }
    }
}
